.wrapper-navigation{
  padding-bottom: 30px;
  .wrapper-main-nav{
    nav.navbar{
      .navbar-brand{
        img{
          @media screen and (max-width: $break-large){
            width: 80%;
          }
        }
      }
      .navbar-toggler{
        border: none;
        background-color: $white;
        .navbar-toggler-icon{
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(204,6,5, 1.0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
      }
      #navbarNavDropdown{
        padding-left: 75px;
        @media screen and (max-width: $break-large){
          padding-left: 0;
        }
        @media screen and (max-width: $break-medium){
          padding-left: 0;
        }
        ul#main-menu{
          padding: 0;
          li.nav-item{
            a.nav-link{
              padding: 8px 14px 0 14px;
              text-align: center;
              font-size: 1.1875rem;
              line-height: 1.375rem;
              font-family: $font-family-primary;
              text-transform: uppercase;
              text-decoration: none;
              color: #333;
              @media screen and (max-width: $break-medium){
                padding: 5px 15px 5px 15px;
              }
            }
            &.active{
              >a.nav-link{
                color: $brand-primary;
                cursor: default;
              }
            }
            &.dropdown{
              ul.dropdown-menu{
                margin-top:5px;
                padding: 0;
                list-style: none;
                background: #e1e1e1;
                border: 1px solid #ccc;
                li.nav-item{
                  border-top: 1px solid #fff;
                  background: #e1e1e1;
                  a.nav-link{
                    text-align: left;
                    padding-left: 30px;
                    font-size: 0.9375rem;
                    @media screen and (max-width: $break-medium){
                      text-align: center;
                      padding: 5px 0 5px 0;
                    }
                  }
                  &:hover{
                    a.nav-link{
                      text-decoration: none;
                      background: $brand-primary;
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}