footer{
  background-color: $brand-primary;
  margin-top: 20px;
  .overlay{
    .footer-block{
      padding-top: 30px;
      h6{
        color: $white;
      }
      p{
        font-size: 0.6875rem;
        color: $white;
        a{
          color: $white;
        }
      }
      @media screen and (max-width: $break-medium){
        text-align: center;
      }
      &.social-block{
        .map, .facebook{
          padding-bottom: 15px;
          padding-top: 30px;
          @media screen and (max-width: $break-medium){
            padding-top: 0;
          }
        }
        .teeth{
          @media screen and (max-width: $break-medium){
            display: none;
          }
        }
      }
      &.hours-block{
        a{
          text-decoration: underline;
        }
        @media screen and (max-width: $break-medium){
          padding-top: 15px;
          background-image: url("../images/pic-tand.jpg");
          background-repeat: no-repeat;
          background-position: left 10px center;
        }
      }
    }
    .copyright-block{
      padding-bottom: 10px;
      span{
        font-size: 0.7875rem;
        color: $white;
        a{
          color: $white;
        }
      }
      img{
        margin-top: 5px;
      }
    }
  }
}