/* -------------
 * Typography
 * -------------*/

@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700');
$body-fsize: 0.8125rem;

.uppercase{
  text-transform: uppercase !important;
}
.text-bold{
  font-weight: bold;
}
body{
  color: $body-text;
  font-family: $font-family-third;
  font-size: $body-fsize;
}

// Headings
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-primary;
  text-transform: uppercase;
  color: $black;
}
h1{
  font-size: 1.375rem;
}
h2{
  font-size: 1.0625rem;
}
h3{
  font-size: 0.875rem;
}
h4{

}
// Text Blocks

p{
  font-family: $font-family-secondary;
  font-size: $body-fsize;
}

// Colors
.txt-orange{
  color: $brand-secondary;
}
.txt-white{
  color: $white;
}
.txt-primary{
  color: $brand-primary;
}
.txt-secondary{
  color: $brand-secondary;
}

// Styles
.text-uppercase{
  text-transform: uppercase;
}
.txt-bold{
  font-weight: bold;
}
.txt-transform-upper{
  text-transform: uppercase;
}
.txt-color-primary{
  color: $brand-primary;
}
.txt-color-secondary{
  color: $brand-secondary;
}


// Links
a {
  color: $link-color;
  text-decoration: none;
  transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}


// Contextual text color
@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $state-success-text);

@include text-emphasis-variant('.text-info', $state-info-text);

@include text-emphasis-variant('.text-warning', $state-warning-text);

@include text-emphasis-variant('.text-danger', $state-danger-text);

// Contextual background
@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $state-success-bg);

@include bg-variant('.bg-info', $state-info-bg);

@include bg-variant('.bg-warning', $state-warning-bg);

@include bg-variant('.bg-danger', $state-danger-bg);


// Others
.text-uppercase{
  text-transform: uppercase;
}
.text-center{
  text-align: center;
}
.text-bold{
  font-weight: bold;
}
.text-muted-2x {
  color: lighten($bs-text-muted, 20%);
}

.text-superbig {
  font-size: 156px;
}

.bold-separator {
  border-top: 3px solid $brand-primary;
}
p{
  margin: 0 0 10px;
}
.section-title{
  padding-top: 35px;
  padding-bottom: 35px;
  font-weight: bold;
}

/*
  Listing
 */
ul.listing-repeater{
  list-style-type: none;
  padding-left:0;
  li.listing-item{
    font-weight: bold;
    &:before{
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00c";
      padding-right: 15px;
    }
  }
}
