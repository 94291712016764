#page-content{
  .simple-content{
    padding-bottom: 30px;
    .overlay{
      .inner-content{
        background-color: $white;
        .image-block{
          padding-left: 0;
          padding-right: 0;
          img{
            width: 100%;
            display: none;
            @media screen and (max-width: $break-medium){
              display: none;
              background-position: center center;
            }
            @media screen and (max-width: $break-small){
              display: inline-block;
            }
          }
          @media screen and (max-width: $break-small){
            background-image: none;
          }
        }
        .content-block{
          padding: 40px 75px 25px 30px;
          h1{
            font-size: 1.375rem;
            padding-bottom: 10px;
          }
          @media screen and (max-width: $break-small){
            padding-right: 15px;
          }
        }
      }
    }
  }
}