.page-27{
  .main-content{
    .overlay{
      .block-item{
        .inner-content{
          .image-block{
            min-height: 220px;
          }
          .content-block{
            .day{
              padding-right: 0;
              @media screen and (max-width: $break-medium){
                padding-right: 15px;
              }
            }
            .hours{
              padding-left: 0;
              @media screen and (max-width: $break-medium){
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
  }
}