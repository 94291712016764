.page-19{
  .main-content{
    .overlay{
      .block-item{
        padding-bottom: 60px;
        .inner-content{
          .image-block{
            @media screen and (min-width: $break-large){
              min-height: 235px;
            }
          }
          .wrapper-content{
            min-height: 120px;
            .content-block{
              min-height: auto;
              padding-top: 10px;
              p{
                font-style: italic;
                font-size: 0.8125rem;
              }
            }
          }
        }
      }
    }
  }
}