#page-contact{
  .contact-block{
    .overlay{
      padding-bottom: 25px;
      .contact-info{
        @media screen and (max-width: $break-medium){
          padding-bottom: 30px;
        }
        .inner-content{
          background-color: $white;
          .contact-image{
            padding-left: 0;
            padding-right: 0;
            img{
              width: 100%;
            }
          }
          .contact-content{
            padding-top: 20px;

          }
        }
      }
      .gmaps-block{

      }
    }
  }
}