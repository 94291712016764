.btn{
  text-decoration: none;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;

  margin: 10px auto 2px auto;
  padding: 14px 32px;
  cursor: pointer;
  font-weight: 700;
  font-family: $font-family-secondary;
  border: $text-gray-2 solid 1px;
  border-radius: 5px;

  i{
    //padding-left: 45px;
    //font-size: 16px;
  }

  &.btn-white{
    background-color: $white!important;
    color: $brand-primary!important;
    &:hover{
      background-color: $brand-primary!important;
      border: 1px solid $brand-primary!important;
      color: $white!important;
    }
  }
  &.btn-primary{
    background-color: $brand-primary!important;
    border: $brand-primary solid 1px!important;
    color: $white!important;
    border-radius: 5px;
    &:hover, &:active, &:focus{
      color: $white!important;
      background-color: $brand-secondary!important;
      border: $brand-secondary solid 1px!important;
    }
  }
  &:focus{
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }
}

.btn-return{
  text-transform: uppercase;
  color: $brand-secondary;
  background-color: $white;
  border-color: $brand-secondary;
}

.btn-cta-short{
  padding: 15px 35px;
  font-size: 16px;
  color: $brand-primary;
  background-color: $white;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.54);

  text-decoration: none;
  transition: color 0.3s linear;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  transition: background-color 0.3s linear;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  i{
    padding-left: 15px;
  }
  &:hover{
    color: $white;
    background-color: $brand-primary;
  }
}