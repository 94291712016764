.main-content{
  $content-padding: 20px;
  .overlay{
    .block-item{
      padding-bottom: 30px;
      .inner-content{
        //background: $white url(../images/bg-shadow.jpg) no-repeat right bottom;
        background-color: $white;
        height: 100%;

        .image-block{
          min-height: 350px;
          &.bg-switch{
            @media screen and (max-width: $break-medium){
              background-image: none!important;
              min-height: auto;
              padding-left: 0;
              padding-right: 0;
            }
            img.mobileon{
              display: none;
              @media screen and (max-width: $break-medium){
                display: inline-block;
                width: 100%;
              }
            }
          }
          &.bg-always{
            img{
              display: none;
            }
            @media screen and (max-width: $break-medium){
              min-height: 265px;
              img.mobileon{
                display: none;
              }
            }
          }
        }
        .wrapper-content{
          .title-block{
            padding-top: 20px;
            padding-left: $content-padding;
            padding-right: $content-padding;
            h2{
              font-size: 1.375rem;
              margin-bottom: 0;
              a{
                color: $black;
              }
            }
          }
          .content-block{
            min-height: 175px;
            padding: 20px $content-padding;
          }
          .cta-block{
            text-align: right;
            padding-bottom: 25px;
            padding-left: $content-padding;
            padding-right: $content-padding;
            margin-top: -10px;
            a{
              &.btn-round{
                padding: 1px 7px 1px 10px;
                border-radius: 100%;
                border-color: #E5E5E5;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,e5e5e5+100 */
                background: rgb(247,247,247); /* Old browsers */
                background: -moz-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(229,229,229,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(247,247,247,1) 0%,rgba(229,229,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(229,229,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
              }
              &.arrow-right{
                color: $red;
              }
            }
            @media screen and (max-width: $break-small){
              margin-top:0;
              a{
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}