.container{
  @media screen and (max-width: $break-large){
    max-width: 960px!important;
  }
}
body {
  min-height: 100vh;
  background: #dddddd url(../images/bg.jpg) no-repeat center top;
}

img{
  max-width: 100%;
}
.section-padding{
  padding-top: 25px;
  padding-bottom: 25px;
}
.section-padding-top{
  padding-top: 25px;
}
.section-padding-bottom{
  padding-bottom: 25px;
}

.box h3{
  text-align:center;
  position:relative;
  top:80px;
}
.box {
  width:70%;
  height:200px;
  background:#FFF;
  margin:40px auto;
}

/*==================================================
 * Shadow Box
 * ===============================================*/
.shadowbox {
  position: relative;
  &.both-sides{
    &:before {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
  &.left-side{
    &:before {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
  }
  &.right-side{
    &:after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  }
}