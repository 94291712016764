.page-1{
  .main-content{
    .overlay{
      .block-item{
        &.block-1{
          @media screen and (max-width: $break-small){
            text-align: center;
          }
        }
      }
    }
  }
}